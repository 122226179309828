<template>
  <data-form2
    minheight="350px"
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import ArticlesDataService from "../../services/articles.service";

export default {
  name: "article-form",
  props: ["data"],
  components: {
    DataForm2,
  },
  data() {
    return { message: "" };
  },
  computed: {
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            { name: { label: "Naziv", type: "text" } },
            { serial_nr: { label: "Šifra artikla", type: "text" } },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              uom: {
                label: "JM",
                type: "dropdown",
                url: "/units",
                dropdownHeight: "200px",
              },
            },
            {
              tax: {
                label: "Stopa",
                type: "dropdown",
                url: "/tax-rates",
                list: this.$store.state.app.taxRates,
              },
            },
            {
              default_for: {
                label: "Automatski za dokument iz outbounda",
                type: "dropdown",
                list: [
                  { id: "Proform", name: "Proform" },
                  { id: "Advance", name: "Advance" },
                  { id: "Normal", name: "Normal" },
                ],
              },
            },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              additional_required: {
                label: "Zahteva dodatne podatke na računu",
                type: "checkbox",
              },
            },
            {
              wheels_nr_required: {
                label: "Obavezan unos Wheels Broja na računu",
                type: "checkbox",
              },
            },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              in_cession: {
                label: "Prikazi u formi cesija",
                type: "checkbox",
              },
            },
            {
              in_sell: {
                label: "Prikazi u formi prodaja",
                type: "checkbox",
              },
            },
            {},
            {}
            ],
        ],
      };
    },
  },
  methods: {
    saveData(newData) {
      if (newData && newData.id) {
        this.update(newData);
      } else {
        this.create(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      ArticlesDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Artikli su promenjeni!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      ArticlesDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Artikal je kreiran!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>