<template>
  <div class="list row">
    <h2>Artikli</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      />
      <button
        type="button"
        class="btn btn-success bi bi-plus"
        @click="addData"
      />
    </div>
    <data-table
      url="articles"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="Articles"
    >
    </data-table>
  </div>
</template>

<script>
import ArticlesDataService from "../../services/articles.service";
import DataTable from "../widgets/DataTable.vue";
import ArticleForm from "./ArticleForm.vue";

export default {
  name: "articles",
  components: {
    DataTable,
  },
  data() {
    return {
      columns: [
        "name",
        "serial_nr",
        "unit.name",
        "tax-rate.name",
        "tax-rate.rate",
        "edit",
      ],
      headers: {
        name: "Naziv",
        serial_nr: "Šifra artikla",
        "unit.name": "JM",
        "tax-rate.name": "Stopa",
        "tax-rate.rate": "Stopa (%)",
        edit: "",
      },
      templates: {},
      actions: {
        edit: { commp: ArticleForm },
        delete: {
          deleteData: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        // print: null,
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: ArticleForm,
        params: { data: {}, title: "Dodaj novu stopu" },
      });
    },
    deleteData(id) {
      ArticlesDataService.delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
</style>
